<template>
  <q-card>
    <q-card-section class="row justify-between items-center">
      <q-btn
        flat
        dense
        icon="arrow_backward"
        label="back"
        @click="router.go(-1)"
      ></q-btn>
    </q-card-section>
    <q-card-section class="no-border-radius f12 q-py-none">
      <q-markup-table dense flat class="bg-transparent">
        <tr>
          <td style="padding-left: 0 !important">Total Order</td>
          <td>:</td>
          <td>{{ data.summary.total }}</td>
        </tr>
        <tr>
          <td style="padding-left: 0 !important">Order Diterima</td>
          <td>:</td>
          <td>{{ data.summary.acc }}</td>
        </tr>
        <tr>
          <td style="padding-left: 0 !important">Order Ditolak</td>
          <td>:</td>
          <td>{{ data.summary.dec }}</td>
        </tr>
        <tr>
          <td style="padding-left: 0 !important">Belum Diproses</td>
          <td>:</td>
          <td>{{ data.summary.wait }}</td>
        </tr>
      </q-markup-table>
    </q-card-section>
    <div>
      <q-card-section>
        <q-btn
          outline
          label="FILTER"
          @click="dialogFilter = true"
          class="full-width"
        ></q-btn>
      </q-card-section>
      <div>
        <q-input
          outlined
          dense
          square
          v-model="data.filter.searchTerm"
          label="Cari Nama/No.HP"
        >
          <template v-slot:append>
            <q-icon name="search"></q-icon>
          </template>
        </q-input>
        <q-markup-table
          class="stickyTable bg-grey-3"
          square
          wrap-cells
          separator="horizontal"
          :style="{ height: `${sHeight - 351}px` }"
        >
          <thead>
            <tr>
              <th>Waktu</th>
              <th>Nama</th>
              <th>No. HP</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody class="bg-white">
            <tr
              class="cursor-pointer"
              v-for="(val, i) in filteredList"
              :key="i"
              @click="
                data.selOrder = val;
                dialogDetail = true;
              "
            >
              <td>{{ moment(val.created).format("DD/MM/YY HH:mm") }}</td>
              <td>{{ val.nama }}</td>
              <td class="cursor-pointer" @click.stop.prevent="toWhatsapp(val)">
                <a
                  :href="`https://api.whatsapp.com/send?phone=${theNumber(
                    val.hp
                  )}&text=Assalamu%27alaikum%2C%20${val.nama}`"
                >
                  {{ val.hp }}</a
                >
              </td>
              <td class="text-center">
                <q-icon
                  v-if="val.status == 'ACC'"
                  size="sm"
                  name="check_circle"
                  color="positive"
                ></q-icon>
                <q-icon
                  v-if="val.status == 'DEC'"
                  size="sm"
                  name="dangerous"
                  color="negative"
                ></q-icon>
                <q-icon
                  v-if="val.status == 'WAIT'"
                  size="sm"
                  name="schedule"
                ></q-icon>
              </td>
            </tr>
          </tbody>
        </q-markup-table>
      </div>
      <q-inner-loading :showing="isLoadingData">
        <q-spinner-gears size="50px" color="primary" />
      </q-inner-loading>
    </div>
    <q-dialog v-model="dialogDetail">
      <q-card style="width: 300px">
        <q-card-section horizontal class="justify-between items-center">
          <q-btn flat icon="close" v-close-popup></q-btn>
          <q-card-section class="q-px-none">
            <q-markup-table dense wrap-cells flat>
              <tr>
                <td>Nama</td>
                <td>:</td>
                <td>{{ data.selOrder.nama }}</td>
              </tr>
              <tr>
                <td>Tanggal</td>
                <td>:</td>
                <td>
                  {{
                    moment(data.selOrder.created).format("DD MMM YYYY HH:mm")
                  }}
                </td>
              </tr>
              <tr>
                <td>Total</td>
                <td>:</td>
                <td>
                  {{ rupiah(data.selOrder.nominal) }}
                </td>
              </tr>
            </q-markup-table>
          </q-card-section>
          <q-btn
            flat
            icon="arrow_forward"
            @click="dialogEdit = true"
            v-close-popup
          ></q-btn>
        </q-card-section>
        <q-card-section>
          <q-img
            fit="container"
            :src="`./bukti_transfer/${data.selOrder.file_transfer}`"
            class="bg-grey-8 q-mx-auto"
            style="width: 250px"
            v-if="data.selOrder.pay_method == 'TF'"
          ></q-img>
          <div
            class="row justify-center items-center text-center"
            style="width: 250px; height: 250px"
            v-else
          >
            <div>
              <span class="text-bold">Tidak ada Bukti Transfer</span>
              <br />
              <span class="text-bold">Pembayaran Melalui CASH</span>
            </div>
          </div>
        </q-card-section>
        <div class="row">
          <q-btn
            unelevated
            class="no-border-radius"
            label="DECLINE"
            color="negative"
            style="width: 50%"
            @click="
              waitThis(() => {
                processOrder('DEC');
              })
            "
          ></q-btn>
          <q-btn
            unelevated
            class="no-border-radius"
            label="ACCEPT"
            color="positive"
            style="width: 50%"
            @click="
              waitThis(() => {
                processOrder('ACC');
              })
            "
          ></q-btn>
        </div>
      </q-card>
    </q-dialog>
    <q-dialog persistent v-model="dialogEdit">
      <q-card style="width: 300px">
        <q-card-section class="row justify-between items-center">
          <div class="text-h6">Detail Order</div>
          <q-btn flat icon="close" v-close-popup></q-btn>
        </q-card-section>
        <q-scroll-area :style="{ height: `${sHeight - 250}px` }">
          <q-card-section>
            <div class="column q-gutter-sm">
              <div class="q-gutter-xs">
                <span>Nama</span>
                <q-input
                  outlined
                  readonly
                  v-model="data.selOrder.nama"
                ></q-input>
              </div>
              <div class="q-gutter-xs">
                <span>No. HP</span>
                <q-input
                  class="cursor-pointer"
                  outlined
                  readonly
                  v-model="data.selOrder.hp"
                >
                </q-input>
              </div>
              <div class="q-gutter-xs">
                <span>Kelompok</span>
                <q-input
                  outlined
                  readonly
                  v-model="data.selOrder.kelompok"
                ></q-input>
              </div>
            </div>
          </q-card-section>
          <q-card-section class="bg-grey-4" style="border: 1px solid black">
            <div class="column q-gutter-sm">
              <q-card-section class="bg-white row justify-between items-center">
                <div>Beras</div>
                <div>
                  @
                  {{
                    rupiah(
                      parseInt(data.selOrder.minyak) +
                        parseInt(data.selOrder.beras) >=
                        10
                        ? data.selOrder.beras_agen
                        : data.selOrder.beras_price
                    )
                  }}
                </div>
                <div>
                  <q-input
                    dense
                    outlined
                    type="number"
                    style="width: 60px"
                    v-model="data.selOrder.beras"
                    :readonly="data.selOrder.status != 'WAIT'"
                  ></q-input>
                </div>
              </q-card-section>
              <q-card-section class="bg-white row justify-between items-center">
                <div>Minyak</div>
                <div>
                  @
                  {{
                    rupiah(
                      parseInt(data.selOrder.minyak) +
                        parseInt(data.selOrder.beras) >=
                        10
                        ? data.selOrder.minyak_agen
                        : data.selOrder.minyak_price
                    )
                  }}
                </div>
                <div>
                  <q-input
                    dense
                    outlined
                    type="number"
                    style="width: 60px"
                    v-model="data.selOrder.minyak"
                    :readonly="data.selOrder.status != 'WAIT'"
                  ></q-input>
                </div>
              </q-card-section>
            </div>
          </q-card-section>
          <q-card-section
            class="bg-grey-4 row justify-between items-center text-body1 text-bold"
            style="border: 1px solid black"
          >
            <div>TOTAL</div>
            <div>{{ rupiah(total_price) }}</div>
          </q-card-section>
          <q-card-actions align="stretch">
            <div class="column q-gutter-xs full-width">
              <span>Nominal</span>
              <q-input
                outlined
                v-model="data.selOrder.nominal"
                prefix="Rp."
                type="number"
              ></q-input>
            </div>
            <q-btn
              unelevated
              class="full-width q-mt-md"
              color="positive"
              label="SAVE & ACCEPT"
              @click="
                waitThis(() => {
                  saveOrder('ACC');
                })
              "
            ></q-btn>
            <q-btn
              outline
              class="full-width q-mt-md"
              label="SAVE"
              @click="
                waitThis(() => {
                  saveOrder('SAVE');
                })
              "
            ></q-btn>
            <q-btn
              unelevated
              class="full-width q-mt-md"
              color="negative"
              label="DECLINE"
              @click="
                waitThis(() => {
                  processOrder('DEC');
                })
              "
            ></q-btn>
          </q-card-actions>
        </q-scroll-area>
      </q-card>
    </q-dialog>
    <q-dialog v-model="dialogFilter" position="bottom">
      <q-card style="width: 300px">
        <q-card-section class="text-h6"> Filter </q-card-section>
        <q-card-section class="q-gutter-y-sm">
          <div class="q-gutter-y-xs">
            <span>Status</span>
            <q-select
              outlined
              multiple
              :options="data.optStatus"
              v-model="data.filter.status"
              map-options
              emit-value
            >
              <template
                v-slot:option="{ itemProps, opt, selected, toggleOption }"
              >
                <q-item v-bind="itemProps">
                  <q-item-section>
                    <q-item-label v-html="opt.label" />
                  </q-item-section>
                  <q-item-section side>
                    <q-toggle
                      :model-value="selected"
                      @update:model-value="toggleOption(opt)"
                    />
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>
          <div class="q-gutter-xs">
            <span>Tanggal Order</span>
            <q-input outlined v-model="data.filter.date" readonly>
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy
                    ref="qDateProxy"
                    cover
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date v-model="data.filter.date">
                      <div class="row items-center justify-end">
                        <q-btn
                          v-close-popup
                          label="Reset"
                          color="primary"
                          @click="data.filter.date = null"
                          flat
                        />
                        <q-btn
                          v-close-popup
                          label="Close"
                          color="primary"
                          unelevated
                        />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn unelevated color="primary" label="Close" v-close-popup></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-card>
</template>

<script>
import useBasic from "@/composables/useBasic";
import { computed } from "@vue/runtime-core";
export default {
  setup() {
    const {
      reactive,
      ref,
      onMounted,
      route,
      router,
      loadThis,
      isLoadingData,
      $http,
      moment,
      waitThis,
      thousand,
      rupiah,
      $q,
      sHeight,
    } = useBasic();

    let data = reactive({
      user: {
        nama: "John Doe",
        hp: "+62812345678",
        kelompok: "3",
      },

      summary: {
        total: 0,
        acc: 0,
        dec: 0,
        wait: 0,
      },

      order: [],

      filter: {
        searchTerm: "",
        date: null,
        status: ["WAIT"],
      },

      optStatus: [
        { label: "MENUNGGU", value: "WAIT" },
        { label: "DIPROSES", value: "ACC" },
        { label: "DITOLAK", value: "DEC" },
      ],

      selOrder: {},

      id_periode: 0,
    });

    onMounted(() => {
      data.id_periode = localStorage.getItem("period-id");
      loadThis(getData);
    });

    let getData = async () => {
      await getTotal();
      await getSummary();
      await getOrder();
    };

    let getTotal = async () => {
      let resp = await $http.get(`/total-order/${data.id_periode}`);
      data.summary.total = resp.data.banyak;
    };

    let getSummary = async () => {
      let resp = await $http.get(`/count-summary/${data.id_periode}`);

      let temp = resp.data.find((x) => {
        return x.status == "ACC";
      });
      if (temp) data.summary.acc = temp.banyak;

      temp = resp.data.find((x) => {
        return x.status == "DEC";
      });
      if (temp) data.summary.dec = temp.banyak;

      temp = resp.data.find((x) => {
        return x.status == "WAIT";
      });
      if (temp) data.summary.wait = temp.banyak;
    };

    let getOrder = async () => {
      let resp = await $http.get(`/list-order/${data.id_periode}/ASC`);
      data.order = resp.data;
    };

    let filteredList = computed(() => {
      let temp = data.order.filter((val) => {
        let cNama =
          val.nama
            .toLowerCase()
            .indexOf(data.filter.searchTerm.toLowerCase()) != -1;
        let cHP =
          val.hp.toLowerCase().indexOf(data.filter.searchTerm.toLowerCase()) !=
          -1;

        return cNama || cHP;
      });

      temp = temp.filter((val) => {
        return data.filter.status.includes(val.status);
      });

      if (data.filter.date) {
        temp = temp.filter((val) => {
          return (
            moment(val.created).format("YYYY-MM-DD") ==
            moment(data.filter.date).format("YYYY-MM-DD")
          );
        });
      }

      return temp;
    });

    let dataModel = computed(() => {
      if (typeof data.filter.date == "object") {
        return `${moment(data.filter.date.from).format(
          "DD MMM YYYY"
        )} - ${moment(data.filter.date.to).format("DD MMM YYYY")}`;
      } else if (typeof data.filter.date == "string") {
        return moment(data.filter.date).format("DD MMM YYYY");
      } else {
        return data.filter.date;
      }
    });

    let processOrder = async (stat) => {
      try {
        let payload = JSON.parse(JSON.stringify(data.selOrder));
        payload.status = stat;

        await $http.put(`/order/status`, payload);

        $q.notify({ message: "Proses Data berhasil!", color: "positive" });
        await getOrder();
        dialogDetail.value = false;
        dialogEdit.value = false;
      } catch (err) {
        $q.notify({ message: "Proses Data gagal!", color: "negative" });
      }
    };

    let saveOrder = async (stat) => {
      try {
        let payload = JSON.parse(JSON.stringify(data.selOrder));
        payload.status = stat;

        await $http.put(`/order/save`, payload);

        if (stat == "ACC") {
          $q.notify({
            message: "Proses & Simpan Data berhasil!",
            color: "positive",
          });
        } else {
          $q.notify({
            message: "Simpan Data berhasil!",
            color: "positive",
          });
        }
        await getOrder();
        dialogEdit.value = false;
      } catch (err) {
        $q.notify({
          message: "Proses / Simpan Data gagal!",
          color: "negative",
        });
      }
    };

    let total_price = computed(() => {
      let total = 0;
      if (
        parseInt(data.selOrder.minyak) + parseInt(data.selOrder.beras) >=
        10
      ) {
        total =
          data.selOrder.minyak * data.selOrder.minyak_agen +
          data.selOrder.beras * data.selOrder.beras_agen;
      } else {
        total =
          data.selOrder.minyak * data.selOrder.minyak_price +
          data.selOrder.beras * data.selOrder.beras_price;
      }
      return total;
    });

    let toWhatsapp = (val) => {
      let payload = JSON.parse(JSON.stringify(val));
      let number = payload.hp;
      if (number.split("")[0] == "0") {
        number = number.split("");
        number[0] = "+62";
        number = number.join("");
      }

      let link = `https://api.whatsapp.com/send?phone=${number}&text=Assalamu%27alaikum%2C%20${val.nama}`;

      window.open(link, "_blank");
    };

    let theNumber = (hp) => {
      let number = hp.slice();
      if (number.split("")[0] == "0") {
        number = number.split("");
        number[0] = "+62";
        number = number.join("");
      }
      return number;
    };

    let dialogDetail = ref(false);
    let dialogEdit = ref(false);
    let dialogFilter = ref(false);

    return {
      data,
      dialogDetail,
      dialogEdit,
      isLoadingData,
      sHeight,
      filteredList,
      moment,
      processOrder,
      dialogFilter,
      dataModel,
      saveOrder,
      total_price,
      router,
      thousand,
      rupiah,
      waitThis,
      toWhatsapp,
      theNumber,
    };
  },
};
</script>
